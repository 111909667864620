$headerBackgroundColor: #2c2e2f;
$metallundmehrColor: #006bb3;
$weissmalerColor: #1fa44b;
$wertschoepferRed: #b61615;
$wertschoepferOrange: #f78b1f;

@mixin button-modifier($color) {
    background-color: $color;
    border-color: $color;
    color: #fff;

    &:hover {
        background-color: lighten($color, 5%);
        border-color: lighten($color, 5%);
        color: #fff;
    }
}

@mixin txt--theme-color($color, $hoverColor: $color) {
    a,
    .txt--theme-color {
        font-weight: $font-weight--bold;
        color: $color;
    }

    a:hover {
        color: lighten($hoverColor, 5%);
    }
}

.partner {
    position: relative;

    &--metallundmehr {
        .button--primary {
            @include button-modifier($metallundmehrColor);
        }

        .partner__header {
            background: $headerBackgroundColor url(../../../images/landingpage/metallundmehr/header320.jpg) no-repeat 50% 0;
            background-size: contain;

            @include media(lg) {
                background-image: url(../../../images/landingpage/metallundmehr/header768.jpg);
                background-size: initial;
            }

            @include media(xxl) {
                background-image: url(../../../images/landingpage/metallundmehr/header1330.jpg);
                height: 700px;
            }
        }

        .partner__section {
            @include txt--theme-color($metallundmehrColor);

            &--benefits li .fa {
                background-color: $metallundmehrColor;
            }

            &--about {
                background-color: $grey--light;
            }
        }

        .partner__price {
            color: $metallundmehrColor;
        }
    }

    &--weissmaler {
        .button--primary {
            @include button-modifier($weissmalerColor);
        }

        .partner__header {
            background: $headerBackgroundColor url(../../../images/landingpage/weissmaler/header320.jpg) no-repeat 50% 0;

            @include media(lg) {
                background-image: url(../../../images/landingpage/weissmaler/header768.jpg);
            }

            @include media(xxl) {
                background-image: url(../../../images/landingpage/weissmaler/header1330.jpg);
                height: 700px;
            }
        }

        .partner__section {
            @include txt--theme-color($weissmalerColor);

            &--benefits {
                background-color: rgba(#c2e8d3, 0.35);
            }

            &--benefits li .fa {
                background-color: $weissmalerColor;
            }
        }

        .partner__price {
            color: $weissmalerColor;
        }

        .partner__product {
            padding: $space * 4;
            min-width: 100%;

            @include media(md) {
                min-width: 50%;
            }

            @include media(lg) {
                min-width: 0;
            }

            img {
                margin-bottom: $space * 2;
            }
        }
    }

    &--wertschoepfer {
        .button--primary {
            @include button-modifier($wertschoepferRed);
        }

        .partner__header {
            background: $headerBackgroundColor url(../../../images/landingpage/wertschoepfer/header320.jpg) no-repeat 50% 0;

            @include media(lg) {
                background-image: url(../../../images/landingpage/wertschoepfer/header768.jpg);
            }

            @include media(xxl) {
                background-image: url(../../../images/landingpage/wertschoepfer/header1330.jpg);
                height: 700px;
            }
        }

        .partner__section {
            @include txt--theme-color($wertschoepferOrange, $wertschoepferRed);

            &--benefits {
                background-color: $wertschoepferOrange;
                color: #fff;
            }

            &--benefits li .fa {
                background-color: $wertschoepferRed;
            }
        }

        .partner__price {
            color: $wertschoepferOrange;
        }

        .partner__product {
            padding: $space * 4;
            min-width: 100%;

            @include media(md) {
                min-width: 50%;
            }

            @include media(lg) {
                min-width: 0;
            }

            img {
                margin-bottom: $space * 2;
            }
        }
    }

    .button--primary {
        &[disabled] {
            @include border($color: $grey);
            background-color: $grey--light;
            color: $grey--dark;
            cursor: not-allowed;
        }
    }

    &__header {
        height: 230px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__hero {
        position: relative;
        z-index: 1;

        .container {
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }

    &__scroll-down-indicator {
        background-color: #fff;
        border-radius: 5px 5px 0 0;
        bottom: 0;
        display: none;
        font-size: 20px;
        left: 50%;
        padding: $space * 2;
        position: absolute;
        transform: translateX(-50%);
    }

    &__headline {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 230px;
        padding: $space * 6;

        h1 {
            font-family: $font-family--main;
            font-size: 30px;
            line-height: 1em;
            margin-bottom: $space * 2;
        }

        p {
            font-size: $font-size--medium;
        }

        h1,
        p {
            font-weight: $font-weight--bold;
        }
    }

    &__form {
        @include border(bottom, $color: $grey--light);
        border-radius: 0;
        box-shadow: none;
        margin-left: -$space * 2;
        margin-right: -$space * 2;

        label {
            font-weight: $font-weight--bold;
            margin-bottom: $space;
            margin-top: $space * 2;

            &.required::after {
                color: $base-text-color;
            }
        }

        p {
            font-size: $font-size--regular;
            line-height: $line-height--regular;
        }
    }

    &__section {
        padding-bottom: $space * 8;
        padding-top: $space * 8;

        h2 {
            font-family: $font-family--main;
            font-size: 36px;
            line-height: 1.3em;
            margin-bottom: $space * 2;
        }

        p {
            font-size: 16px;
            line-height: 1.375em;
        }

        &--about,
        &--benefits {
            .container {
                max-width: 945px;
            }
        }

        &--benefits {
            ul {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                align-items: center;
                display: flex;
                margin-top: $space * 3;
                min-width: 100%;
            }

            li .fa {
                align-items: center;
                border-radius: 50%;
                color: #fff;
                display: flex;
                font-size: 20px;
                min-height: 36px;
                min-width: 36px;
                max-height: 36px;
                max-width: 36px;
                justify-content: center;
                margin-right: $space * 4;
            }

            li span {
                font-size: 16px;
                font-weight: $font-weight--bold;
            }
        }

        &--contact {
            background-color: $grey--light;

            img {
                max-height: 72px;
                max-width: 72px;
                margin-right: $space * 3;
            }

            p {
                font-size: $font-size--regular;
                line-height: $line-height--regular;
            }
        }

        &--logos {
            padding-top: $space * 5;
            padding-bottom: $space * 5;

            .slider__content > * {
                max-width: 120px;
                flex-shrink: 0;
                margin-right: $space * 10;
            }

            .slider__content > *:last-child {
                margin-right: 0;
            }
        }
    }

    &__price {
        font-family: $font-family--main;
        font-size: 30px !important;
        line-height: 1em;
        margin-top: $space * 3;

        small {
            font-family: $font-family--regular;
            font-size: 13px;
        }

        + p {
            margin-top: -10px;
        }
    }

    @include media(lg) {
        &__headline {
            max-width: 60%;
            justify-content: center;

            h1 {
                font-size: 34px;
            }
        }

        &__section {
            &--benefits li {
                max-width: 33.333%;
                min-width: 33.333%;
            }

            &--logos .slider__content,
            &--products .slider__content {
                justify-content: center;
            }
        }
    }

    @include media(xl) {
        &__product {
            max-width: 300px;
        }
    }

    @include media(xxl) {
        &__hero {
            .container {
                align-items: center;
                flex-direction: row;
                height: 700px;
                justify-content: space-between;
            }
        }

        &__scroll-down-indicator {
            display: block;
        }

        &__headline {
            margin-left: 120px;
            max-width: 550px;

            h1 {
                font-size: 60px;
            }

            p {
                font-size: 24px;
                line-height: 28px;
            }
        }

        &__form {
            border-bottom: 0;
            border-radius: $border-radius;
            box-shadow: $shadow6;
            margin-right: 120px;
            max-width: 470px;

            .panel__header {
                padding-left: $space * 6;
                padding-right: $space * 6;
            }

            .panel__body {
                padding: ($space * 4) ($space * 6) ($space * 6) ($space * 6);
            }
        }
    }
}

.page-partner-registerpartner,
.page-weissmaler-registerweissmaler,
.page-diewertschoepfer-registerwertschoepfer {
    .footer {
        margin-top: 0 !important;
    }

    .alert {
        margin: ($space * 3) 0 !important;
    }
}

;@import "sass-embedded-legacy-load-done:790";