/* THIS FILE IS BEING AUTO-GENERATED */
$contorion-font-family: "contorionFont";

[class*="icon--"]::before {
    display: inline-block;
    font-family: $contorion-font-family;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$contorion-icon--01000000: "\EA01";

$contorion-icon--02000000: "\EA02";

$contorion-icon--03000000: "\EA03";

$contorion-icon--04000000: "\EA04";

$contorion-icon--05000000: "\EA05";

$contorion-icon--07000000: "\EA06";

$contorion-icon--080000000000: "\EA07";

$contorion-icon--09000000: "\EA08";

$contorion-icon--10000000: "\EA09";

$contorion-icon--11000000: "\EA0A";

$contorion-icon--13000000: "\EA0B";

$contorion-icon--14000000: "\EA0C";

$contorion-icon--15000000: "\EA0D";

$contorion-icon--16000000: "\EA0E";

$contorion-icon--19000000: "\EA0F";

$contorion-icon--20000000: "\EA10";

$contorion-icon--21000000: "\EA11";

$contorion-icon--22000000: "\EA12";

$contorion-icon--23000000: "\EA13";

$contorion-icon--230000000000: "\EA14";

$contorion-icon--250000000000: "\EA15";

$contorion-icon--280000000000: "\EA16";

$contorion-icon--300000000000: "\EA17";

$contorion-icon--500000000000: "\EA18";

$contorion-icon--account: "\EA19";

$contorion-icon--add-wishlist: "\EA1A";

$contorion-icon--addtocart-icon: "\EA1B";

$contorion-icon--addwishlist: "\EA1C";

$contorion-icon--all-categories: "\EA1D";

$contorion-icon--best-quality-offer: "\EA1E";

$contorion-icon--bpja: "\EA1F";

$contorion-icon--burger: "\EA20";

$contorion-icon--c-plus: "\EA21";

$contorion-icon--cart-icon: "\EA22";

$contorion-icon--cart-plus-v2: "\EA23";

$contorion-icon--check-shield: "\EA24";

$contorion-icon--check: "\EA25";

$contorion-icon--chevron-left-thin: "\EA26";

$contorion-icon--chevron-right-thin: "\EA27";

$contorion-icon--clock-solid: "\EA28";

$contorion-icon--contorion-c: "\EA29";

$contorion-icon--contorion-merch: "\EA2A";

$contorion-icon--customer-data: "\EA2B";

$contorion-icon--empty-rating-star: "\EA2C";

$contorion-icon--express-delivery-icon: "\EA2D";

$contorion-icon--express-delivery: "\EA2E";

$contorion-icon--extremely-unhappy: "\EA2F";

$contorion-icon--fifteen-euro-coupon-icon: "\EA30";

$contorion-icon--fifteen-euro-coupon: "\EA31";

$contorion-icon--filter-icon: "\EA32";

$contorion-icon--fire: "\EA33";

$contorion-icon--gift: "\EA34";

$contorion-icon--half-rating-star: "\EA35";

$contorion-icon--hamburger: "\EA36";

$contorion-icon--happy: "\EA37";

$contorion-icon--harry-hammer: "\EA38";

$contorion-icon--heart: "\EA39";

$contorion-icon--logistic-icon: "\EA3A";

$contorion-icon--navigation-azubistipendium: "\EA3B";

$contorion-icon--navigation-broom: "\EA3C";

$contorion-icon--navigation-canister: "\EA3D";

$contorion-icon--navigation-compressor: "\EA3E";

$contorion-icon--navigation-contorion: "\EA3F";

$contorion-icon--navigation-doorhandle: "\EA40";

$contorion-icon--navigation-helmet: "\EA41";

$contorion-icon--navigation-ladder: "\EA42";

$contorion-icon--navigation-new: "\EA43";

$contorion-icon--navigation-percent: "\EA44";

$contorion-icon--navigation-protractor: "\EA45";

$contorion-icon--navigation-sawblade: "\EA46";

$contorion-icon--navigation-screw: "\EA47";

$contorion-icon--navigation-screwdriver: "\EA48";

$contorion-icon--navigation-stier: "\EA49";

$contorion-icon--navigation-tools: "\EA4A";

$contorion-icon--navigation-weldingtorch: "\EA4B";

$contorion-icon--navigation-wheelbarrow: "\EA4C";

$contorion-icon--neutral: "\EA4D";

$contorion-icon--new-chevron-left: "\EA4E";

$contorion-icon--new-chevron-right: "\EA4F";

$contorion-icon--new: "\EA50";

$contorion-icon--offers: "\EA51";

$contorion-icon--order-approval: "\EA52";

$contorion-icon--orders: "\EA53";

$contorion-icon--overview: "\EA54";

$contorion-icon--pdf: "\EA55";

$contorion-icon--play-thumb: "\EA56";

$contorion-icon--private-label-inverted: "\EA57";

$contorion-icon--private-label: "\EA58";

$contorion-icon--profi-store: "\EA59";

$contorion-icon--rating-star: "\EA5A";

$contorion-icon--reoccurring: "\EA5B";

$contorion-icon--ringed_truck: "\EA5C";

$contorion-icon--save_for_later-icon: "\EA5D";

$contorion-icon--security_certification: "\EA5E";

$contorion-icon--ten-euro-coupon: "\EA5F";

$contorion-icon--time: "\EA60";

$contorion-icon--trash-can: "\EA61";

$contorion-icon--unlucky: "\EA62";

$contorion-icon--very-happy: "\EA63";

$contorion-icon--warenstapler: "\EA64";

$contorion-icon--wishlist-icon: "\EA65";

$contorion-icon--wishlist: "\EA66";



.icon--01000000::before { content: $contorion-icon--01000000; }

.icon--02000000::before { content: $contorion-icon--02000000; }

.icon--03000000::before { content: $contorion-icon--03000000; }

.icon--04000000::before { content: $contorion-icon--04000000; }

.icon--05000000::before { content: $contorion-icon--05000000; }

.icon--07000000::before { content: $contorion-icon--07000000; }

.icon--080000000000::before { content: $contorion-icon--080000000000; }

.icon--09000000::before { content: $contorion-icon--09000000; }

.icon--10000000::before { content: $contorion-icon--10000000; }

.icon--11000000::before { content: $contorion-icon--11000000; }

.icon--13000000::before { content: $contorion-icon--13000000; }

.icon--14000000::before { content: $contorion-icon--14000000; }

.icon--15000000::before { content: $contorion-icon--15000000; }

.icon--16000000::before { content: $contorion-icon--16000000; }

.icon--19000000::before { content: $contorion-icon--19000000; }

.icon--20000000::before { content: $contorion-icon--20000000; }

.icon--21000000::before { content: $contorion-icon--21000000; }

.icon--22000000::before { content: $contorion-icon--22000000; }

.icon--23000000::before { content: $contorion-icon--23000000; }

.icon--230000000000::before { content: $contorion-icon--230000000000; }

.icon--250000000000::before { content: $contorion-icon--250000000000; }

.icon--280000000000::before { content: $contorion-icon--280000000000; }

.icon--300000000000::before { content: $contorion-icon--300000000000; }

.icon--500000000000::before { content: $contorion-icon--500000000000; }

.icon--account::before { content: $contorion-icon--account; }

.icon--add-wishlist::before { content: $contorion-icon--add-wishlist; }

.icon--addtocart-icon::before { content: $contorion-icon--addtocart-icon; }

.icon--addwishlist::before { content: $contorion-icon--addwishlist; }

.icon--all-categories::before { content: $contorion-icon--all-categories; }

.icon--best-quality-offer::before { content: $contorion-icon--best-quality-offer; }

.icon--bpja::before { content: $contorion-icon--bpja; }

.icon--burger::before { content: $contorion-icon--burger; }

.icon--c-plus::before { content: $contorion-icon--c-plus; }

.icon--cart-icon::before { content: $contorion-icon--cart-icon; }

.icon--cart-plus-v2::before { content: $contorion-icon--cart-plus-v2; }

.icon--check-shield::before { content: $contorion-icon--check-shield; }

.icon--check::before { content: $contorion-icon--check; }

.icon--chevron-left-thin::before { content: $contorion-icon--chevron-left-thin; }

.icon--chevron-right-thin::before { content: $contorion-icon--chevron-right-thin; }

.icon--clock-solid::before { content: $contorion-icon--clock-solid; }

.icon--contorion-c::before { content: $contorion-icon--contorion-c; }

.icon--contorion-merch::before { content: $contorion-icon--contorion-merch; }

.icon--customer-data::before { content: $contorion-icon--customer-data; }

.icon--empty-rating-star::before { content: $contorion-icon--empty-rating-star; }

.icon--express-delivery-icon::before { content: $contorion-icon--express-delivery-icon; }

.icon--express-delivery::before { content: $contorion-icon--express-delivery; }

.icon--extremely-unhappy::before { content: $contorion-icon--extremely-unhappy; }

.icon--fifteen-euro-coupon-icon::before { content: $contorion-icon--fifteen-euro-coupon-icon; }

.icon--fifteen-euro-coupon::before { content: $contorion-icon--fifteen-euro-coupon; }

.icon--filter-icon::before { content: $contorion-icon--filter-icon; }

.icon--fire::before { content: $contorion-icon--fire; }

.icon--gift::before { content: $contorion-icon--gift; }

.icon--half-rating-star::before { content: $contorion-icon--half-rating-star; }

.icon--hamburger::before { content: $contorion-icon--hamburger; }

.icon--happy::before { content: $contorion-icon--happy; }

.icon--harry-hammer::before { content: $contorion-icon--harry-hammer; }

.icon--heart::before { content: $contorion-icon--heart; }

.icon--logistic-icon::before { content: $contorion-icon--logistic-icon; }

.icon--navigation-azubistipendium::before { content: $contorion-icon--navigation-azubistipendium; }

.icon--navigation-broom::before { content: $contorion-icon--navigation-broom; }

.icon--navigation-canister::before { content: $contorion-icon--navigation-canister; }

.icon--navigation-compressor::before { content: $contorion-icon--navigation-compressor; }

.icon--navigation-contorion::before { content: $contorion-icon--navigation-contorion; }

.icon--navigation-doorhandle::before { content: $contorion-icon--navigation-doorhandle; }

.icon--navigation-helmet::before { content: $contorion-icon--navigation-helmet; }

.icon--navigation-ladder::before { content: $contorion-icon--navigation-ladder; }

.icon--navigation-new::before { content: $contorion-icon--navigation-new; }

.icon--navigation-percent::before { content: $contorion-icon--navigation-percent; }

.icon--navigation-protractor::before { content: $contorion-icon--navigation-protractor; }

.icon--navigation-sawblade::before { content: $contorion-icon--navigation-sawblade; }

.icon--navigation-screw::before { content: $contorion-icon--navigation-screw; }

.icon--navigation-screwdriver::before { content: $contorion-icon--navigation-screwdriver; }

.icon--navigation-stier::before { content: $contorion-icon--navigation-stier; }

.icon--navigation-tools::before { content: $contorion-icon--navigation-tools; }

.icon--navigation-weldingtorch::before { content: $contorion-icon--navigation-weldingtorch; }

.icon--navigation-wheelbarrow::before { content: $contorion-icon--navigation-wheelbarrow; }

.icon--neutral::before { content: $contorion-icon--neutral; }

.icon--new-chevron-left::before { content: $contorion-icon--new-chevron-left; }

.icon--new-chevron-right::before { content: $contorion-icon--new-chevron-right; }

.icon--new::before { content: $contorion-icon--new; }

.icon--offers::before { content: $contorion-icon--offers; }

.icon--order-approval::before { content: $contorion-icon--order-approval; }

.icon--orders::before { content: $contorion-icon--orders; }

.icon--overview::before { content: $contorion-icon--overview; }

.icon--pdf::before { content: $contorion-icon--pdf; }

.icon--play-thumb::before { content: $contorion-icon--play-thumb; }

.icon--private-label-inverted::before { content: $contorion-icon--private-label-inverted; }

.icon--private-label::before { content: $contorion-icon--private-label; }

.icon--profi-store::before { content: $contorion-icon--profi-store; }

.icon--rating-star::before { content: $contorion-icon--rating-star; }

.icon--reoccurring::before { content: $contorion-icon--reoccurring; }

.icon--ringed_truck::before { content: $contorion-icon--ringed_truck; }

.icon--save_for_later-icon::before { content: $contorion-icon--save_for_later-icon; }

.icon--security_certification::before { content: $contorion-icon--security_certification; }

.icon--ten-euro-coupon::before { content: $contorion-icon--ten-euro-coupon; }

.icon--time::before { content: $contorion-icon--time; }

.icon--trash-can::before { content: $contorion-icon--trash-can; }

.icon--unlucky::before { content: $contorion-icon--unlucky; }

.icon--very-happy::before { content: $contorion-icon--very-happy; }

.icon--warenstapler::before { content: $contorion-icon--warenstapler; }

.icon--wishlist-icon::before { content: $contorion-icon--wishlist-icon; }

.icon--wishlist::before { content: $contorion-icon--wishlist; }


;@import "sass-embedded-legacy-load-done:118";