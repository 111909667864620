.o-menu {
    background-color: white;
    transition: all 0.25s ease;
    float: right;
    right: 0;
    width: 0;

    &.show {
        width: calc(100vw - 40px);
        transition: all 0.25s ease;
    }

    &-list {
        padding-bottom: $space * 5;
        padding-top: $space * 16;

        &.menu-with-footer {
            padding-bottom: 0;
        }

        li.m-menu-item__bordered:nth-last-of-type(1) {
            p {
                border: 0;
            }

        }
    }

    &-footer {
        margin-top: $space * 10;
        background-color: $grey--light;
    }
}

;@import "sass-embedded-legacy-load-done:1334";