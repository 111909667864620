.o-logo-pool {
    &__title {
        margin-bottom: $space * 14;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ($space * 2) ($space * 4);

        &__item {
            justify-content: center;
            align-items: center;
            display: flex;
            padding: ($space * 3) ($space * 4);
        }
    }

    &__logo {
        object-fit: contain;
        width: 110px;
        height: 67px;
    }

    &__bottom {
        margin-top: $space * 4;

        &-text {
            display: none;
        }

        &-btn {
            width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1416";