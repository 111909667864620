.o-image-gallery {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
    }

    &__preview-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__image-slider-container {
        align-items: center;
        min-height: 340px;
    }

    &__preview-image--picture {
        cursor: zoom-in;
    }

    &__preview-image--slider {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        position: relative;
        min-width: 100%;
        max-width: 100%;
    }

    &__thumb-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        align-items: flex-end;
    }

    &__video--wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__video--inline {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 400px;
    }

    &__bundle {
        position: relative;
        width: 490px;
        height: 490px;
        margin: auto;
        overflow: hidden;

        &-thumb {
            width: 80px;
            height: 80px;
        }

        &-slide {
            height: 490px;
            max-width: 490px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1302";