input,
select,
textarea {
    &[disabled],
    &[readonly] {
        color: $grey--dark;
        border-color: $grey;
        background-color: $grey--light;
        background-image: none;
        padding-left: 8px;
        padding-right: 8px;
        user-select: none;
        cursor: not-allowed;
    }
}

;@import "sass-embedded-legacy-load-done:774";