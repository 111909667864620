/* ------------------------------------ *\
    BREAKPOINTS
\* ------------------------------------ */
$grid-breakpoints: (
    sm: 361px,
    md: 480px,
    lg: 769px,
    xl: 960px,
    xxl: 1280px
);

$grid-columns: (
    sm: 6,
    md: 6,
    lg: 12,
    xl: 24,
    xxl: 24
);

/* ------------------------------------ *\
    SPACES
\* ------------------------------------ */
$space: 5px;

/* ------------------------------------ *\
    COLOR VARIABLES
\* ------------------------------------ */

//Blue
$blue: #09f;
$blue--light: #eff7ff;
$blue--medium: #dfefff;
$blue--facebook: #3b5998;
$blue--linkedin: #0074b5;

//Grey
$grey: #ccc;
$grey--medium: #e5e5e5;
$grey--lighter: #f9f9f9;
$grey--light: #f3f3f3;
$grey--dark: #999;
$grey--darker: #2c2c2c;
$grey--darkest: #1c1c1c;
$grey--placeholder: #9a999a;
$grey--navigation: #404040;
$color--cancelled: #666;

//Green
$green: #5da422;
$green--light: #d3eba1;
$color--success: #8bc34a;

//Green Arbeitssicherheit
$green--abs: #83b81b;

//Red
$red: #c50e12;
$red--light: #fae7e7;
$red--google: #dd4b39;
$red--youtube: #e52d27;

//pink
$pink--instagram: #e70060;

//Yellow
$yellow: #e3b000;
$yellow--light: #fdf7e6;
$yellow--dark: #a98628;
$yellow--paypal: #ffc34d;

//Orange
$orange: #f3b760;

//Default theme
$theme-color: $blue;

/* ------------------------------------ *\
    TEXT VARIABLES
\* ------------------------------------ */

$font-family--regular: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family--main: "Alternate Gothic", Impact, Arial, sans-serif;
$font-family--font-awesome: "FontAwesome";

$line-height: 1.2;

$font-size--giant: 60px;
$font-size--xxlarge: 56px;
$font-size--xlarge-plus: 36px;
$font-size--xlarge: 30px;
$font-size--large: 24px;
$font-size--medium-plus: 18px;
$font-size--medium: 16px;
$font-size--regular-plus: 14px;
$font-size--regular: 13px;
$font-size--small: 11px;
$font-size--smaller: 9px;

$font-size--icon: 20px;

$line-height--giant: 60px;
$line-height--xlarge: 36px;
$line-height--large-plus: 30px;
$line-height--large: 24px;
$line-height--list: 21px;
$line-height--regular-plus: 20px;
$line-height--regular: 18px;
$line-height--medium: 16px;
$line-height--small: 14px;

$line-height--icon: 32px;

$font-weight--base: 400;
$font-weight--light: 300;
$font-weight--bold: 600;

$base-font-family: $font-family--regular;
$base-font-size: $font-size--regular;
$base-line-height: $line-height--regular;
$base-text-color: $grey--darker;

// deprecated, please don't use this, will be removed soon
$font-sizes: (
    micro: $font-size--small,
    micro-dense: $font-size--small,
    small: $font-size--regular,
    small-plus: $font-size--regular-plus,
    regular: $font-size--regular,
    medium: $font-size--medium,
    medium-regular: $font-size--medium,
    medium-plus: $font-size--medium-plus,
    large: $font-size--large,
    extra: $font-size--xlarge,
    extra-plus: $font-size--xlarge-plus,
    giant: $font-size--giant,
);

// deprecated, please don't use this, will be removed soon
$line-heights: (
    micro: $line-height--large,
    micro-dense: $line-height--small,
    small: $line-height--large,
    small-plus: $line-height--small,
    regular: $line-height--regular,
    medium: $line-height--large,
    medium-regular: $line-height--regular,
    medium-plus: $line-height--large,
    large: $line-height--large,
    extra: $line-height--large-plus,
    extra-plus: $line-height--xlarge,
    giant: $line-height--giant,
);

$button-font-sizes: (
    big: $font-size--regular,
    large: $font-size--medium,
);

$button-line-heights: (
    big: $line-height--regular,
    large: $line-height--regular,
);

/* ------------------------------------ *\
    BORDER VARIABLES
\* ------------------------------------ */
$border-radius: 2px;
$border-default-color: $grey;

/* ------------------------------------ *\
    Z-INDEX VARIABLES
\* ------------------------------------ */
$z1: 100;
$z2: 200;
$z3: 300;
$z4: 400;
$z5: 500;
$z-modal: 600;
$z-max: 9999;

/* ------------------------------------ *\
    BOX SHADOW VARIABLES
\* ------------------------------------ */
$box-shadow-navigation-mobile: inset -5px 0 7px -2px rgba(#000, 0.35);
$box-shadow-navigation-mobile-filters: inset 5px 0 7px rgba(#000, 0.35);

$box-shadow-button--nav-left: 2px 0 3px 0 rgba(#000, 0.2);
$box-shadow-button--nav-left__hover: 3px 0 5px 0 rgba(#000, 0.2);
$box-shadow-button--nav-left--inactive: -2px 0 3px 0 rgba(#000, 0.1);
$box-shadow-button--nav-right: -2px 0 3px 0 rgba(#000, 0.2);
$box-shadow-button--nav-right__hover: -3px 0 5px 0 rgba(#000, 0.2);
$box-shadow-button--nav-right--inactive: 2px 0 3px 0 rgba(#000, 0.1);

$shadow1: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$shadow2: 0 0 2px 0 rgba(#000, 0.12), 0 2px 2px 0 rgba(#000, 0.24);
$shadow3: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);
$shadow4: 0 14px 28px rgba(#000, 0.25), 0 10px 10px rgba(#000, 0.22);
$shadow5: 0 19px 38px rgba(#000, 0.3), 0 15px 12px rgba(#000, 0.22);
$shadow6: 0 1px 2px 0 #ccc;
$shadow7: 0 3px 3px rgba(#000, 0.22), 0 0 3px rgba(#000, 0.34);
$shadow8: 0 0 10px 0 rgba(0, 0, 0, 0.23), 0 6px 6px 0 rgba(0, 0, 0, 0.19);
$shadow9: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);

$panel-shadow: 0 1px 2px 0 $grey;
$shadow-bottom-only: 0 8px 6px -6px $grey;
$shadow-top-only: 0 -8px 6px -6px $grey;

/* ------------------------------------ *\
    MISCELLANEOUS VARIABLES
\* ------------------------------------ */
$transition-ease: cubic-bezier(0.4, 0, 0.2, 1);

/* ------------------------------------ *\
        ANIMATION VARIABLES
\* ------------------------------------ */
$animation-duration-regular : 0.4s;

/* ------------------------------------ *\
    IMAGE SIZE VARIABLES
\* ------------------------------------ */
$image-size-z: 1100px;
$image-size-xxl: 720px;
$image-size-xl: 490px;
$image-size-lg: 320px;
$image-size-xm: 180px;
$image-size-ml: 110px;
$image-size-m: 80px;
$image-size-s: 42px;
$image-size-xs: 32px;

;@import "sass-embedded-legacy-load-done:42";