.product-highlights {
    margin-top: $space * 3;
    padding-left: $space;
    display: none;

    &__title {
        font-weight: $font-weight--bold;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@include media(lg) {
    .product-highlights {
        display: block;
    }
}

@include media(xl) {
    .product-highlights {
        padding-right: $space;
    }
}

;@import "sass-embedded-legacy-load-done:429";