.o-usp-banner {
    padding-top: $space * 2;
    padding-bottom: $space * 2;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $space * 8;
    flex-wrap: wrap;
    max-width: 440px;

    &--dark {
        background: $grey--darker;
    }

    &__item {
        display: flex;
        padding: ($space * 6) ($space * 7);
        width: 80%;

        @include border($where: bottom, $color: $grey--navigation);

        &:last-child {
            border: none;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        font-size: 40px;
        margin-bottom: 0;
    }

    &__txt {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: $space * 7;
        display: flex;
        line-height: $line-height--large;
    }

    &__title,
    &__subtitle {
        width: 100%;
    }

    &--brushed {
        margin-bottom: 100px;
        @include grow-outside-container;
        background-color: $grey--darker;
    }
}

.template-campaign {
    .o-usp-banner {
        margin-top: $space * 14;
    }
}

;@import "sass-embedded-legacy-load-done:1314";