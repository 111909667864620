.o-login-redirection-card {
    &-login {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 1px 2px 0 $grey;
        padding: $space * 3;
        gap: $space * 3;
        margin-top: $space * 4;

        &__header {
            display: inline;
            align-items: baseline;
            &--redirect {
                text-decoration: none;
                margin-left: 3px;
            }
            p {
                display: inline;
            }
        }
    }

    &__redirect-login {
        align-self: center;
        width: 100%;

        &-button {
            border-color: $blue;
            color: $blue;
            width: 100%;
        }
    }

    &-divider {
        display: flex;
        position: relative;
        margin: $space * 4 0 $space 0;

        &__text {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            background: $grey--light;
            padding: 0 $space * 3;
        }
    }

    &__info {
        display: flex;
        align-items: center;
    }
}

;@import "sass-embedded-legacy-load-done:1440";