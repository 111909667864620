.a-divider {
    &__container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $space * 14;
    }

    &__line {
        width: 100%;
        display: block;

        @include border($where: 'bottom', $color: $grey--light, $width: 3px);

        &--left,
        &--right {
            margin-left: $space * 6;
            margin-right: $space * 6;
        }
    }

    &__icon,
    &__icon.fa {
        align-items: center;
        justify-content: center;
        width: $space * 8;
        height: $space * 8;
        font-size: $space * 8;
        line-height: $space * 8;
        margin: auto;
        color: $grey;
    }
}

;@import "sass-embedded-legacy-load-done:953";