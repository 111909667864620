.nav-brands {
    display: none;

    @include media(lg) {
        display: flex;
        flex-wrap: wrap;

        .header__nav-item-text {
            justify-content: center;
        }
    }

    @include media(xxl) {
        flex-wrap: nowrap;
        @include border(bottom);
        margin-bottom: 20px;
    }

    &.hover {
        @include media(lg) {
            background-color: white;
            border-bottom: 2px solid white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-weight: $font-weight--base;
        }

        .headline {
            font-weight: $font-weight--bold;
            font-size: $font-size--medium;
        }

        .navigation {
            z-index: 300;
            display: block;
        }

        .header__nav-item-link {
            color: $blue;
        }

        .menu {
            min-width: 736px;
            left: -209px;
            top: 2px;
            box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);

            @include media(xl) {
                min-width: 925px;
                left: -208px;
            }

            @include media(xxl) {
                min-width: 1297px;
            }
        }

        .menu--offers {
            left: -349px;

            .icons {
                font-size: 16px;
                color: $grey--dark;
            }

            @include media(xl) {
                left: -348px;
            }
        }

        .menu--magazine {
            left: -489px;

            @include media(xl) {
                left: -488px;
            }
        }

        .wrapper {
            padding: 20px;
            flex-wrap: wrap;
        }
    }

    &__sec {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        padding-bottom: 20px;

        @include border(bottom);

        .headline {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    &__links {
        width: 14%;
        margin-right: 40px;
        @include border(right);

        @include media(xxl) {
            width: 21%;
        }

        & > div {
            display: flex;
            line-height: $line-height--large;
        }

        &--last {
            @include media(lg) {
                width: 50%;
            }

            @include media(xxl) {
                width: 32%;
            }

            border: none;
        }
    }

    &__sec1 {
        @include media(xxl) {
            margin-right: 50px;
            border-bottom: none;
            padding-bottom: 0;

            @include border(right);
        }

        img {
            max-width: 100px;
        }

        .images {
            width: 19%;
            margin-bottom: $space * 4;

            img {
                width: 100px;
                height: 60px;
            }
        }
    }

    &__sec2 {
        width: 100%;
        @include media(xxl) {
            width: 58%;
            border-bottom: none;
        }
    }

    &__all {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &__text {
        div {
            display: inline-block;
            text-decoration: none;
        }
    }

    .menu__list & {
        display: flex;
        flex-direction: column;

        &__sec {
            flex-direction: row;
            border-bottom: 0;
            margin-bottom: 0;

            .headline {
                @include border(bottom, $color: $grey--light);
                font-size: $font-size--regular-plus;
                font-weight: $font-weight--bold;
                padding: ($space * 2) ($space * 4);
            }
        }

        &__sec1 {
            border-bottom: 0;
            padding: 0;

            .images {
                flex: 1;
                width: auto;
                margin: 0 ($space * 2);
                text-align: center;
            }
        }

        &__text {
            display: none;
        }

        &__links {
            border-right: 0;
            margin: 0 ($space * 4);
            width: auto;

            &--last {
                display: none;
            }

            & .link--dark {
                margin: $space 0 $space ($space * 4);
            }
        }

        &__all {
            padding: $space * 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:867";