//TODO:Deprecated - Remove when all usages are replaced
//The only usages remained are the ones which have been added via raw html content in ZED.
//Usages are needed to be replaced directly in DB.
//Refactored version: /styles/v2/components/link.atom.scss
a,
.link {
    display: inline-block;
    color: $theme-color;
    text-decoration: none;

    @include input(hoverable) {
        &:hover {
            text-decoration: underline;
        }
    }
}

.link--dark {
    color: $base-text-color;

    @include input(hoverable) {
        &:hover {
            color: $theme-color;
            text-decoration: none;
        }
    }
}

.link--icon {
    position: relative;
    padding-left: $space * 2;

    & > .fa {
        position: absolute;
        top: 2px; //default for .fa-angle-*
        left: 0;
    }
}


;@import "sass-embedded-legacy-load-done:163";