@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.ca-announcement {
    align-items: center;
    display: flex;
    padding: $space * 4;
    position: relative;

    h3 {
        font-size: 16px;
        font-weight: $font-weight--bold;
    }

    i {
        background-color: $theme-color;
        border-radius: 20px;
        color: #fff;
        font-size: $font-size--medium;
        height: 25px;
        margin-right: $space * 2;
        max-width: 25px;
        min-width: 25px;
        position: relative;

        &::before {
            content: $fa-var-info;
            font-family: "FontAwesome";
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .btn--close {
        position: absolute;
        right: $space * 2;
        top: $space * 4;
    }

    &:first-child {
        margin-top: -($space * 4);
    }

    .alert + & {
        margin-top: -$space;
    }
}

;@import "sass-embedded-legacy-load-done:580";