@include media(md) {
    .o-logo-pool {
        &__container {
            grid-template-columns: repeat(4, 1fr);
            gap: $space * 2;

            &__item {
                padding: $space * 3;
            }
        }

        &__logo {
            width: 137px;
            height: 64px;
        }

        &__bottom {
            display: flex;
            justify-content: flex-end;
            &-text {
                display: block;
                text-align: end;
            }

            &-btn {
                display: none;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1420";