.o-full-picture-block {
    @include grow-outside-container;
    margin-bottom: $space * 14;

    &__image {
        padding-bottom: $space * 2;
        aspect-ratio: 16/9;
        width: 100%;
        height: unset;
    }

    &__text {
        margin: 0 $space * 4;
    }
}

;@import "sass-embedded-legacy-load-done:1087";