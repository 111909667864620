.o-newsletter-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $grey--darker;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: (6 * $space) (4 * $space);
    }

    &__cta-text {
        text-align: center;
        width: 100%;
    }

    &__usps {
        display: flex;
        flex-direction: column;
        row-gap: $space;
        align-items: center;
        margin-top: 4 * $space;
        margin-bottom: 6 * $space;
    }

    &__usp {
        display: flex;
        flex-direction: row;
        column-gap: $space;
        align-items: baseline;
        vertical-align: middle;
    }

    &__usp-check {
        color: $blue;
        font-size: $font-size--medium;
    }

    &__form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 4 * $space;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        cursor: pointer;
        border: none;
        height: 40px;
        width: 100%;
    }

    &__email-input-wrapper {
        position: relative;
        flex-basis: 100%;

        input {
            height: 48px;
        }
    }

    // success component style
    &__success-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: (25 * $space) (8 * $space) (28 * $space) (8 * $space);
    }

    &__success-check {
        color: $blue;
        margin-bottom: 6 * $space;
        &::before {
            font-size: 40px;
        }
    }

    &__success-title {
        text-align: center;
        margin-bottom: 4 * $space;
    }

    &__success-subtitle {
        text-align: center;
    }
}

;@import "sass-embedded-legacy-load-done:1110";