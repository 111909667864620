.o-product-reviews {
    &__headline {
        @include border($where: bottom);

        &--mobile {
            @include border($where: bottom);
            @include border($where: top);

            @include media(lg) {
                border-top: 0;
            }
        }
    }

    &__container {
        margin-top: $space * 3;
        margin-bottom: $space * 3;
    }
}
;@import "sass-embedded-legacy-load-done:1298";