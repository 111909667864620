input[type="radio"] {
    + span {
        border-radius: 50%;

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $theme-color;
        }
    }
}

;@import "sass-embedded-legacy-load-done:765";