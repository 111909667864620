@include media (md) {
    .o-usp-banner {
        max-width: 628px;
        &__item {
            padding: ($space * 6) ($space * 7) ($space * 6) 25%;
            width: 90%;
        }
    }

    .template-campaign {
        .o-usp-banner {
            margin-top: $space * 28;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1316";