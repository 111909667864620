$logo-max-height-desktop: 75px;

@include media(lg) {
    .brandpage {
        &__logo-container--big {
            width: 150px;
            height: $logo-max-height-desktop;
        }

        &__logo-image--big {
            img {
                max-height: $logo-max-height-desktop;
            }
        }
    }

    .hero-banner {
        &__eyecatcher-container {
            right: 96px;
        }

        &__content--container {
            justify-content: space-between;
            padding: $space * 6;
        }

        &__text--preheadline {
            font-size: $font-size--large;
            line-height: $line-height--large-plus;
        }

        &__text--headline {
            font-size: $font-size--giant;
            line-height: $line-height--giant;
            max-height: 125px;
        }

        &__full-width {
            .brandpage__banner {
                aspect-ratio: 3 / 1;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1003";