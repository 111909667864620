$size: 18px;

.info {
    align-items: center;
    display: flex;

    & > .fa {
        align-items: center;
        background-color: $theme-color;
        border-radius: $size;
        color: #fff;
        display: flex;
        font-size: round($size * 0.6);
        justify-content: center;
        margin-right: $space * 2;
        max-height: $size;
        max-width: $size;
        min-height: $size;
        min-width: $size;
    }
}

;@import "sass-embedded-legacy-load-done:303";