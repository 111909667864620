.o-product-buy-box-v2 {
    $s2: $space * 2;
    $s3: $space * 3;
    $s4: $space * 4;
    $s6: $space * 6;
    $s8: $space * 8;
    $s14: $space * 14;

    width: 100%;
    margin-bottom: $s8;

    &__divider {
        margin: $s6 0;
    }

    &__bundle-label {
        display: inline-block;
    }

    &__bundle-components {
        margin-bottom: $s6;
    }

    &__product-name {
        margin-top: $s2;
        margin-bottom: $s3;
    }

    &__rating-stars {
        margin-bottom: $s6;
    }

    &__prices {
        margin-bottom: $s3;
    }

    &__product-buy {
        margin-bottom: $s3;
        margin-top: $s8;
    }

    &__product-buy-bundle {
        margin-bottom: $s3;
        margin-top: $s4;
    }

    &__delivery-time {
        margin-bottom: $s3;
        padding-left: $s2;
    }

    &__haulier-shipment {
        margin-bottom: $s3;
        padding-left: $s2;
    }

    &__paypal-banner {
        margin-top: $s6;
    }

    &__brand-and-product-info {
        margin-bottom: $s8;
    }

    &__hazard-info {
        margin-bottom: $s14;
    }

    &__energy-label {
        margin-top: $space * 4;
    }

    &__btn-similar-products {
        width: 100%;
        margin: ($space * 2) 0;
    }
}

;@import "sass-embedded-legacy-load-done:1306";